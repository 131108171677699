
























































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useMembershipCards } from '@/admin/membershipCard';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import FcStaticImage from '@/components/FcStaticImage.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'MembershipCards',
  components: {
    FcStaticImage,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('membershipcards'));

    const { membershipCards } = useMembershipCards();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const headers = [
      { text: 'タイトル', value: 'title', sortable: false },
      { text: '会員証', value: 'image', sortable: false },
      { text: '閲覧期間', value: 'accessDate', sortable: false },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    const isDeleting = ref(false);
    const deleteCard = async (membershipCardId: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      isDeleting.value = true;
      try {
        await membershipCards.delete(membershipCardId);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
      isDeleting.value = false;
    };

    return { myRoleSettings, headers, membershipCards, isDeleting, deleteCard };
  },
});
