import core from '@/admin/core';
import { reactive } from '@vue/composition-api';
import { DisplayDate } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';

interface Permission {
  acceptAllMembers: boolean;
  acceptAllUsers: boolean;
  deny: boolean;
  planIds?: string[];
  productIds?: string[];
  giftIds?: string[];
  seasonIds?: number[];
}

interface MembershipCardResponse {
  membershipCardId: string;
  title: string;
  image: string;
  textColor: string;
  authority: Permission;
  order: number;
  accessStartDate?: number;
  accessEndDate?: number;
}

interface MembershipCardProperty {
  membershipCardId: string;
  title: string;
  image: string;
  textColor: string;
  authority: Permission;
  order: number;
  accessStartDate: DisplayDate;
  accessEndDate: DisplayDate;
}

interface MembershipCardProps {
  title: string;
  image: string;
  textColor: string;
  authority: Permission;
  order?: number;
  isDefault?: boolean;
  accessStartDate?: number;
  accessEndDate?: number;
}

const getMembershipCards = async (): Promise<MembershipCardResponse[]> => {
  if (myAttributes.myRequestPermissions?.membershipCards) {
    const result = await core.httpClient.get('/admin/public/membershipCards');
    return result.data as MembershipCardResponse[];
  } else return [] as MembershipCardResponse[];
};

export class MembershipCards {
  fetchLoading = false;
  items: MembershipCardResponse[] = [];
  memberCards: MembershipCardResponse[] = [];
  defaultCard: MembershipCardResponse[] = [];

  constructor() {
    this.init();
  }
  init = async () => {
    this.fetchLoading = true;
    this.items = await this.getMembershipCards();
    this.defaultCard = this.items.filter((item) => item.membershipCardId === 'default');
    this.memberCards = this.items
      .filter((item) => item.membershipCardId !== 'default')
      .map((_item) => {
        const item = {
          ..._item,
          accessDate: '',
        };
        const startDate = _item.accessStartDate ? `${new DisplayDate(_item.accessStartDate).dateTime}` : '';
        const endDate = _item.accessEndDate ? `${new DisplayDate(_item.accessEndDate).dateTime}` : '';
        if (startDate || endDate) item.accessDate = `${startDate} ~ ${endDate}`;
        return item;
      });
    this.fetchLoading = false;
  };
  getMembershipCards = async (): Promise<MembershipCardResponse[]> => {
    const items = await getMembershipCards();
    if (items.length) items.sort((a, b) => (a.order > b.order ? 1 : -1));
    return items;
  };
  delete = async (membershipCardId: string) => {
    await core.httpClient.delete(`/admin/public/membershipCards/${membershipCardId}`);
    this.memberCards = this.memberCards.filter((item) => membershipCardId !== item.membershipCardId);
  };
}

export class MembershipCard {
  fetchLoading = false;
  isAccessStartDate = false;
  isAccessEndDate = false;
  item: MembershipCardProperty = {
    membershipCardId: '',
    title: '',
    image: '',
    textColor: '',
    authority: {
      planIds: [],
      giftIds: [],
      productIds: [],
      seasonIds: [],
      acceptAllUsers: false,
      acceptAllMembers: false,
      deny: false,
    },
    accessStartDate: new DisplayDate(),
    accessEndDate: new DisplayDate(),
    order: 0,
  };

  constructor(membershipCardId?: string) {
    this.init(membershipCardId);
  }

  init = async (membershipCardId: string | undefined) => {
    if (membershipCardId) {
      this.fetchLoading = true;
      this.item = await this.getMembershipCard(membershipCardId);
      this.fetchLoading = false;
    }
  };

  getMembershipCard = async (membershipCardId: string): Promise<MembershipCardProperty> => {
    if (myAttributes.myRequestPermissions?.membershipCards) {
      const result = await core.httpClient.get(`/admin/public/membershipCards/${membershipCardId}`);
      const _item = result.data as MembershipCardResponse;
      const item: MembershipCardProperty = {
        membershipCardId: _item.membershipCardId,
        title: _item.title,
        image: _item.image,
        textColor: _item.textColor,
        authority: {
          acceptAllMembers: _item.authority.acceptAllMembers,
          acceptAllUsers: _item.authority.acceptAllUsers,
          deny: _item.authority.deny,
          planIds: _item.authority.planIds || [],
          giftIds: _item.authority.giftIds || [],
          productIds: _item.authority.productIds || [],
          seasonIds: _item.authority.seasonIds || [],
        },
        order: _item.order,
        accessStartDate: new DisplayDate(),
        accessEndDate: new DisplayDate(),
      };
      if (_item.accessStartDate) {
        this.isAccessStartDate = true;
        item.accessStartDate = new DisplayDate(_item.accessStartDate);
      }
      if (_item.accessEndDate) {
        this.isAccessEndDate = true;
        item.accessEndDate = new DisplayDate(_item.accessEndDate);
      }
      return item;
    } else return {} as MembershipCardProperty;
  };

  saveMembershipCard = async (membershipCardId: string) => {
    this.validate();
    const props: MembershipCardProps = {
      title: this.item.title,
      image: this.item.image,
      textColor: this.item.textColor,
      authority: this.item.authority,
      order: this.item.order,
    };
    if (this.isAccessStartDate && this.item.accessStartDate.value) {
      props.accessStartDate = this.item.accessStartDate.value;
    }
    if (this.isAccessEndDate && this.item.accessEndDate.value) {
      props.accessEndDate = this.item.accessEndDate.value;
    }
    await core.httpClient.put(`/admin/public/membershipCards/${membershipCardId}`, props);
  };

  createMembershipCard = async () => {
    this.validate();
    const data = await getMembershipCards();
    const order = data.reduce((acc: number, val: MembershipCardResponse) => {
      const order = val.order && val.order > acc ? val.order : acc;
      return order;
    }, 0);
    const props: MembershipCardProps = {
      title: this.item.title,
      image: this.item.image,
      textColor: this.item.textColor,
      authority: this.item.authority,
      order: order + 1,
    };
    if (this.isAccessStartDate && this.item.accessStartDate.value)
      props.accessStartDate = this.item.accessStartDate.value;
    if (this.isAccessEndDate && this.item.accessEndDate.value) props.accessEndDate = this.item.accessEndDate.value;
    await core.httpClient.post('/admin/public/membershipCards', props);
  };

  createDefaultCard = async () => {
    const props: MembershipCardProps = {
      title: this.item.title,
      image: this.item.image,
      textColor: this.item.textColor,
      authority: {
        planIds: [],
        giftIds: [],
        productIds: [],
        seasonIds: [],
        acceptAllUsers: false,
        acceptAllMembers: true,
        deny: false,
      },
      order: 1,
      isDefault: true,
    };
    await core.httpClient.post('/admin/public/membershipCards', props);
  };

  validate = () => {
    if (!this.item.title || !this.item.image || !this.item.textColor)
      throw 'タイトル、会員証、テキストカラーで未入力があります';
    else if (
      !this.item.authority.acceptAllMembers &&
      !this.item.authority.planIds?.length &&
      !this.item.authority.giftIds?.length &&
      !this.item.authority.productIds?.length &&
      !this.item.authority.seasonIds?.length
    ) {
      throw '権限で未入力があります';
    } else if (
      (this.isAccessStartDate && this.item.accessStartDate.value === undefined) ||
      (this.isAccessEndDate && this.item.accessEndDate.value === undefined)
    ) {
      throw '不正な日時です';
    }
  };
}

export const useMembershipCards = () => {
  const membershipCards = new MembershipCards();
  return { membershipCards: reactive(membershipCards) };
};

export const useMembershipCard = (membershipCardId?: string) => {
  const membershipCard = new MembershipCard(membershipCardId);
  return { membershipCard: reactive(membershipCard) };
};
